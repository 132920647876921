var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fragment",
    [
      _c(
        "v-app-bar",
        {
          style: `${_vm.$vuetify.breakpoint.mdAndUp && "padding-left: 256px;"}`,
          attrs: { color: "primary", dark: "", fixed: "", elevation: "2" },
        },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", icon: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeShowMenu.apply(null, arguments)
                },
              },
            },
            [
              _vm.drawer
                ? _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.breakpoint.smAndUp
                          ? "mdi-chevron-left"
                          : "mdi-menu"
                      )
                    ),
                  ])
                : _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.breakpoint.smAndUp
                          ? "mdi-chevron-right"
                          : "mdi-menu"
                      )
                    ),
                  ]),
            ],
            1
          ),
          _c("v-toolbar-title", { staticClass: "d-flex align-center" }, [
            _vm.$store.getters.getLogoClaro
              ? _c("img", {
                  staticClass: "mb-0",
                  attrs: {
                    src: _vm.$store.getters.getLogoClaro,
                    width: "45",
                    height: "45",
                  },
                })
              : _vm._e(),
            _vm.$store.getters.getLogoComTexto
              ? _c("span", { staticClass: "mt-2 font-weight-bold ml-2" }, [
                  _vm._v(_vm._s(_vm.$store.getters.getAppName)),
                ])
              : _vm._e(),
          ]),
          _c("cronometro", { ref: "cronometro" }),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: {
                right: "",
                bottom: "",
                transition: "scroll-y-transition",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c(
                            "v-badge",
                            {
                              attrs: {
                                color:
                                  _vm.notificacoesAlertas > 0
                                    ? "red"
                                    : "transparent",
                                overlap: "",
                                content:
                                  _vm.notificacoesAlertas > 0
                                    ? _vm.notificacoesAlertas
                                    : "",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "30" } }, [
                                _vm._v("mdi-bell"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _vm._l(_vm.notificacoes.slice(0, 5), function (item, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: index,
                        attrs: { link: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.verProposta(
                              item.internalId,
                              item.guidProposta,
                              item.tipo
                            )
                          },
                        },
                      },
                      [
                        _c("v-sheet", {
                          staticClass: "barra",
                          attrs: {
                            color: item.cor,
                            height: "100",
                            width: "8",
                            rounded: "",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "4px",
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " Proposta nº " + _vm._s(item.nrProposta) + " "
                              ),
                            ]),
                            _c("div", [_vm._v(_vm._s(item.descricao))]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.limparNotificações(_vm.notificacoes)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "border-bottom": "1px solid rgb(114, 114, 114)",
                          },
                        },
                        [_vm._v(" Limpar notificações ")]
                      ),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-menu",
            {
              attrs: {
                right: "",
                bottom: "",
                transition: "scroll-y-transition",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c("v-icon", { attrs: { size: "30" } }, [
                            _vm._v("mdi-account-circle"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$router.push({
                            name: "areaLogada.meuPerfil",
                          })
                        },
                      },
                    },
                    [_c("v-list-item-title", [_vm._v("Meus dados")])],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.logoutApp()
                        },
                      },
                    },
                    [_c("v-list-item-title", [_vm._v("Sair")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.handleClickOutside,
              expression: "handleClickOutside",
            },
          ],
          attrs: {
            fixed: "",
            permanent: _vm.$vuetify.breakpoint.mdAndUp && _vm.drawer,
            "disable-resize-watcher": "",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2 d-flex align-center flex-column",
              attrs: { tile: "", color: "primary" },
            },
            [
              !_vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onClickX.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "#fff", size: "20" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-avatar",
                {
                  staticClass: "avatar-image mb-2 mt-4",
                  attrs: { color: "#ccc", src: "", size: "70" },
                },
                [_c("img", { attrs: { src: _vm.urlFoto, alt: _vm.user.nome } })]
              ),
              _c("span", { staticClass: "white--text subtitle-2 mb-2" }, [
                _vm._v(_vm._s(_vm.user.nome)),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: {
                    color: "primary",
                    small: "",
                    dark: "",
                    to: { name: "areaLogada.meuPerfil" },
                  },
                },
                [_vm._v(" Ver meu perfil ")]
              ),
            ],
            1
          ),
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _vm._l(_vm.options, function (item) {
                return _c(
                  "div",
                  {
                    key: item.title,
                    on: {
                      click: function ($event) {
                        return _vm.menuClick(item.link.name, false)
                      },
                    },
                  },
                  [
                    item.items
                      ? _c(
                          "v-list-group",
                          {
                            attrs: {
                              "no-action": "",
                              color: "primary",
                              "data-test-id": item.title
                                .replaceAll(" ", "-")
                                .toLowerCase(),
                              "prepend-icon": item.action,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(item.icon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.title)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          _vm._l(item.items, function (child) {
                            return _c(
                              "v-list-item",
                              {
                                key: child.title,
                                staticClass: "ml-n10",
                                attrs: {
                                  "data-test-id": child.title
                                    .replaceAll(" ", "-")
                                    .toLowerCase(),
                                  link: "",
                                  to: child.link,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.menuClick(child.link.name, true)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v(_vm._s(child.icon))])],
                                  1
                                ),
                                _c("v-list-item-content", [
                                  _vm._v(_vm._s(child.title) + " "),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c(
                          "v-list-item",
                          {
                            attrs: {
                              exact: "",
                              to: item.link,
                              "data-test-id": item.title
                                .replaceAll(" ", "-")
                                .toLowerCase(),
                              color: "primary",
                              disabled: !item.link && _vm.possuiLeads,
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                              ],
                              1
                            ),
                            !item.link
                              ? _c(
                                  "v-list-item-icon",
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-0",
                                        attrs: { small: "", color: "default" },
                                      },
                                      [_vm._v("Em breve")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                  ],
                  1
                )
              }),
              _c("v-list-item"),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.showModalConfirmacaoDados,
            callback: function ($$v) {
              _vm.showModalConfirmacaoDados = $$v
            },
            expression: "showModalConfirmacaoDados",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Confirmação de dados ")]),
              _c("v-card-text", [
                _vm._v(
                  " Olá " +
                    _vm._s(_vm.user.nome) +
                    ", para acessar é necessário fazer a validação do seu e-mail e telefone. "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pt-1",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onClickConfirmModal()
                        },
                      },
                    },
                    [_vm._v(" VALIDAR ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }