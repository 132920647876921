<template>
  <div v-if="plantao.isAndamento && $route.name == 'areaLogada.lead'" class="cronometro d-none d-sm-flex justify-end">
    <div>
      <v-icon size="10" color="#00E51F" class="mt-2 mr-1">
        mdi-circle
      </v-icon>
    </div>
    <div class="d-none d-md-flex mt-2 mx-1">
      Plantão ativo:
    </div>
    <div class="timer mt-1 ml-1 mr-3">
      {{ showTimer }}
    </div>
    <v-btn class="mx-0 mx-md-2" fab dark x-small color="#ffb61e" @click="pausar">
      <v-progress-circular v-if="loading" size="12" color="black" indeterminate />
      <v-icon v-else color="black">
        mdi-pause
      </v-icon>
    </v-btn>
    <v-divider vertical class="ml-3" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import FLPlantaoService from "@/services/focusLeadPlantaoService";

export default {
  data() {
    return {
      plantao: {
        emAndamento: {},
        isAndamento: false,
      },
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalSeconds: 1,
      },
      iTimer: null,
      loading: false,
      timezone: "America/Sao_Paulo",
    };
  },
  watch: {
    "timer.totalSeconds": {
      handler(value) {
        if (value <= 0) {
          clearInterval(this.iTimer);
          this.buscarPlantoes();
        }
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
      plantaoLeads: (state) => state.lead.plantaoLeads,
    }),
    showTimer() {
      let { hours, minutes, seconds } = JSON.parse(JSON.stringify(this.timer));
      const { days } = JSON.parse(JSON.stringify(this.timer));
      if (Number(days) > 0) {
        const strDays = Number(days) > 1 ? "dias" : "dia";
        return `${days} ${strDays}`;
      }
      hours = Number(hours) < 10 ? `0${hours}` : hours;
      minutes = Number(minutes) < 10 ? `0${minutes}` : minutes;
      seconds = Number(seconds) < 10 ? `0${seconds}` : seconds;
      return `${hours}:${minutes}:${seconds}`;
    },
  },
  methods: {
    async buscarPlantoes() {
      try {
        const plantoes = this.plantaoLeads || [];
        const dataHoraAtual = moment
          .tz(this.timezone)
          .toDate()
          .getTime();
        this.plantao.emAndamento = plantoes.find((p) => p.DataHoraFim >= dataHoraAtual && p.DataHoraInicio <= dataHoraAtual);
        this.plantao.isAndamento = !!(this.plantao.emAndamento && !this.plantao.emAndamento.pausado);
        if (this.plantao.isAndamento) this.startTimer();
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao buscar plantões",
        });
      }
    },
    startTimer() {
      this.iTimer = setInterval(() => {
        const dataHoraAtual = moment.tz(this.plantao.emAndamento.DataHoraFim, this.timezone);
        const dataHoraFinal = moment.tz(this.timezone);

        const m = dataHoraAtual.diff(dataHoraFinal, "minutes");
        const s = dataHoraAtual.diff(dataHoraFinal, "seconds");

        this.timer.days = Math.floor(m / 1440);
        this.timer.hours = Math.floor((m % 1440) / 60);
        this.timer.minutes = Math.floor((m % 1440) % 60);
        this.timer.seconds = Math.floor((s % 1440) % 60);
        this.timer.totalSeconds = s;
      }, 1000);
    },
    async pausar() {
      try {
        this.loading = true;
        const form = JSON.parse(JSON.stringify(this.plantao.emAndamento));
        const body = {
          idPlantao: Number(form.idPlantao),
          CPFCorretor: Number(this.user.cpf),
          acao: true,
          loginExecutor: (this.user.contato && this.user.contato.email) || this.user.nome,
        };
        await FLPlantaoService.mudarStatus(body);
        this.$router.push({ name: "areaLogada.plantaoLeads" });
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao pausar plantão",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.cronometro {
  position: absolute;
  width: calc(100% - 120px);
  @media (max-width: $media-sm) {
    width: calc(100% - 70px);
  }
}

.timer {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
