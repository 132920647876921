var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-bg" },
    [
      _vm.loadingPage
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader-init-wrapper" } },
              [
                _c("vue-topprogress", {
                  ref: "topProgress",
                  attrs: {
                    height: 6,
                    color: _vm.$store.getters.getColor("primary"),
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.loadingPage
        ? _c("the-app-header", {
            on: { closeShowMenu: _vm.closeShowMenu },
            model: {
              value: _vm.drawer,
              callback: function ($$v) {
                _vm.drawer = $$v
              },
              expression: "drawer",
            },
          })
        : _vm._e(),
      !_vm.loadingPage
        ? _c(
            "div",
            {
              style:
                _vm.$vuetify.breakpoint.mdAndUp && !_vm.drawer
                  ? "padding-left: 256px; height: 100%; overflow: hidden;"
                  : "",
            },
            [
              _c("div", {
                staticClass: "header-height",
                style: { background: _vm.$store.getters.getColor("primary") },
              }),
              _c(
                "v-fade-transition",
                { attrs: { "hide-on-leave": "" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.showModalConfirmacaoDados,
            callback: function ($$v) {
              _vm.showModalConfirmacaoDados = $$v
            },
            expression: "showModalConfirmacaoDados",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Confirmação de dados ")]),
              _c("v-card-text", [
                _vm._v(
                  " Olá " +
                    _vm._s(_vm.user.nome) +
                    ", para acessar é necessário fazer a validação do seu e-mail e telefone. "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pt-1",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onClickConfirmModal()
                        },
                      },
                    },
                    [_vm._v(" VALIDAR ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }