var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.plantao.isAndamento && _vm.$route.name == "areaLogada.lead"
    ? _c(
        "div",
        { staticClass: "cronometro d-none d-sm-flex justify-end" },
        [
          _c(
            "div",
            [
              _c(
                "v-icon",
                {
                  staticClass: "mt-2 mr-1",
                  attrs: { size: "10", color: "#00E51F" },
                },
                [_vm._v(" mdi-circle ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "d-none d-md-flex mt-2 mx-1" }, [
            _vm._v(" Plantão ativo: "),
          ]),
          _c("div", { staticClass: "timer mt-1 ml-1 mr-3" }, [
            _vm._v(" " + _vm._s(_vm.showTimer) + " "),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mx-0 mx-md-2",
              attrs: { fab: "", dark: "", "x-small": "", color: "#ffb61e" },
              on: { click: _vm.pausar },
            },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    attrs: { size: "12", color: "black", indeterminate: "" },
                  })
                : _c("v-icon", { attrs: { color: "black" } }, [
                    _vm._v(" mdi-pause "),
                  ]),
            ],
            1
          ),
          _c("v-divider", { staticClass: "ml-3", attrs: { vertical: "" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }