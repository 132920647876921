<template>
  <div class="full-bg">
    <div v-if="loadingPage">
      <div id="loader-init-wrapper">
        <vue-topprogress ref="topProgress" :height="6" :color="$store.getters.getColor('primary')"></vue-topprogress>
      </div>
    </div>

    <the-app-header v-if="!loadingPage" v-model="drawer" @closeShowMenu="closeShowMenu" />
    <div
      v-if="!loadingPage"
      :style="$vuetify.breakpoint.mdAndUp && !drawer ? 'padding-left: 256px; height: 100%; overflow: hidden;' : ''"
    >
      <div
        class="header-height"
        :style="{ background: $store.getters.getColor('primary') }"
      ></div>
      <v-fade-transition hide-on-leave>
        <router-view></router-view>
      </v-fade-transition>
    </div>
    <v-dialog v-model="showModalConfirmacaoDados" persistent max-width="500">
      <v-card>
        <v-card-title>
          Confirmação de dados
        </v-card-title>
        <v-card-text>
          Olá {{ user.nome }}, para acessar é necessário fazer a validação do seu e-mail e telefone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="pt-1" color="primary" @click="onClickConfirmModal()">
            VALIDAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TheAppHeader from "@/components/TheAppHeader.vue";
import { mapState } from "vuex";
import vueTopprogress from "@/components/TopProgress.vue";
import leadService from '@/services/leadService';
import FLPlantaoService from '@/services/focusLeadPlantaoService';

export default {
  name: "AreaLogada",
  components: { TheAppHeader, vueTopprogress },
  data() {
    return {
      loadingPage: true,
      drawer: false,
      showModalConfirmacaoDados: false,
    };
  },
  computed: {
    ...mapState({
      cpf: (state) => state.user.cpf,
      showPage: (state) => state.loadingInit,
      user: (state) => state.user.profile,
    }),
  },
  async mounted() {
    await this.checkConfirmacaoDados();
    await this.getInfoLeadsCorretor();
  },
  methods: {
    async getInfoLeadsCorretor() {
      try {
        this.$refs.topProgress.start();
        this.loadingPage = true;
        const { data } = await leadService.verificacaoInicialLeads();
        if (data) {
          const payload = {
            possuiNovos: !!(data && (data.novosLeads > 0)),
            possuiAlertas: !!(data && (data.novosAlertasTratamento > 0)),
            possuiLeads: !!(data && data.possuiLeads),
            qtNovosLeads: data.novosLeads || 0,
            qtAlertasTratamento: data.novosAlertasTratamento || 0,
          };
          this.$store.dispatch("lead/setStatusLeads", payload);
        }

        if (this.cpf) {
          const plantoes = await FLPlantaoService.buscarPlantoes({ CPFCorretor: Number(this.cpf) });
          this.$store.dispatch("lead/setPlantaoLeads", plantoes);
        }
        this.loadingPage = false;
        this.$refs.topProgress.done();
      } catch (error) {
        this.loadingPage = false;
      }
    },
    async checkConfirmacaoDados() {
      const {
        flagConfirmacaoDados, tentativasConfirmacaoDados, tipoCorretor, subTipoCorretor,
      } = this.user;
      if (
        !flagConfirmacaoDados
        && tipoCorretor !== "Interno"
        && subTipoCorretor !== "PME"
        && subTipoCorretor !== "Atendimento"
        && tentativasConfirmacaoDados
        && tentativasConfirmacaoDados > 3
        && !this.showModalConfirmacaoDados
        && this.$route.name !== "areaLogada.confirmacaoDados"
      ) {
        this.showModalConfirmacaoDados = true;
      }
    },
    onClickConfirmModal() {
      this.showModalConfirmacaoDados = false;
      this.$router.push({ name: 'areaLogada.confirmacaoDados' });
    },
    closeShowMenu(flag) {
      this.drawer = !flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-bg {
  height: 100%;
  flex: 1;
  background: #fff !important;
}
.header-height {
  padding: 20px;
  height: 56px;
  @media (min-width: 960px) {
    height: 64px;
  }

  #loader-init-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffff;
    z-index: 1000;
  }

  .progress {
    z-index: 1001;
  }
}
</style>
<style lang="sass">
.v-navigation-drawer__content
  overflow: hidden !important

  .v-list
    overflow: hidden !important

  .v-list:hover
    overflow-y: auto !important
    height: 75%

  ::-webkit-scrollbar
    width: 8px
  ::-webkit-scrollbar-thumb
    background: #e1e9ee
    border-radius: 0
</style>
